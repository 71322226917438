if ('serviceWorker' in navigator) {
  const u = new URL("./sw.js", import.meta.url);
  const swScriptPolicy = trustedTypes.createPolicy('sw', {
    createScriptURL(input) {
      return u.href;
    },
  });
  const swsanitized = swScriptPolicy.createScriptURL(u);
  navigator.serviceWorker.register(
    swsanitized,
    {type: "module", scope: "/"}
  )
}
